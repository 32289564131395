import { Routes, Route } from "react-router-dom";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import ForgotPasssword from "./pages/Auth/ForgotPasssword";
import AdminRoute from "./components/Routes/AdminRoute";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import CreateCategory from "./pages/Admin/CreateCategory";
import CreateProduct from "./pages/Admin/CreateProduct";
import CreateVideo from "./pages/Admin/CreateVideo";
import Products from "./pages/Admin/Products";
import Videos from "./pages/Admin/Videos";
import UpdateProduct from "./pages/Admin/UpdateProduct";
import ProductDetails from "./pages/ProductDetails";
import Categories from "./pages/Categories";
import CategoryProduct from "./pages/CategoryProduct";
import Home from "./pages/Home";
import WeddingPage from "./pages/WeddingPage";
import Pagenotfound from "./pages/Pagenotfound";
import CandidPage from "./pages/CandidPage";
import PreWeddingPage from "./pages/PreWeddingPage";
import LiveEventsPage from "./pages/LiveEventsPage";
import BabyShowerPage from "./pages/BabyShower";
import SocialWorkPage from "./pages/SocialWork";
import AerialPage from "./pages/Aerial";
import UpdateVideo from "./pages/Admin/UpdateVideo";



function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/wedding" element={<WeddingPage />} />
        <Route path="/candid" element={<CandidPage />} />
        <Route path="/pre-wedding" element={<PreWeddingPage />} />
        <Route path="/live-events" element={<LiveEventsPage />} />
        <Route path="/baby-shower" element={<BabyShowerPage />} />
        <Route path="/social-work" element={<SocialWorkPage />} />
        <Route path="/aerial" element={<AerialPage />} />


        <Route path="/product/:slug" element={<ProductDetails />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/category/:slug" element={<CategoryProduct />} />
        */}
        

        {/* <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/create-category" element={<CreateCategory />} />
          <Route path="admin/create-product" element={<CreateProduct />} />
          <Route path="admin/create-video" element={<CreateVideo />} />
          <Route path="admin/product/:slug" element={<UpdateProduct />} />
          <Route path="admin/video/:slug" element={<UpdateVideo />} />
          <Route path="admin/products" element={<Products />} />
          <Route path="admin/videos" element={<Videos />} />
        </Route> */}
        {/* <Route path="/protected/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPasssword />} />
        <Route path="/protected/login" element={<Login />} /> */}

        
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}

export default App;
