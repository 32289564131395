import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom'
import '../styles/home.css'
import { useTypewriter, Cursor } from 'react-simple-typewriter'
import { FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaYoutubeSquare } from "react-icons/fa";
import { CgRing, CgCamera } from "react-icons/cg";
import { RiImageEditFill } from "react-icons/ri";
import { BsCameraReels } from "react-icons/bs";
import Carousel from 'react-elastic-carousel';
import axios from "axios";




const Home = () => {
    const [videos, setVideos] = useState([]);


    const [text] = useTypewriter({
        words: ['PHOTOGRAPHERS', 'VIDEOGRAPHERS', 'PHOTO & VIDEO EDITORS'],
        loop: 0,
        typeSpeed: 120,
        delaySpeed: 1000,
    })


    //get all featured-video

    // const getAllVideos = async () => {
    //     try {
    //         const { data } = await axios.get("/api/v1/videos/featured-videos");
    //         if (data?.success) {
    //             setVideos(data?.videos);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // useEffect(() => {
    //     getAllVideos();
    // }, []);

    const videosList = [
       'https://www.youtube.com/embed/NNwsBQvu86Y',
       'https://www.youtube.com/embed/pls0AHmAevs',
       'https://www.youtube.com/embed/YcBbmX3F1c8',
       'https://www.youtube.com/embed/QecxgaNnYiU',
       'https://www.youtube.com/embed/cHyLr-84-po',
       'https://www.youtube.com/embed/Y6SeW6dOI4k',
       'https://www.youtube.com/embed/olCkKqA5fME'   
    ]


    //carousel
    const carouselRef = useRef(null);
    let resetTimeout;


    return (
       
        <>

            <div id="head">
                <div id="header1" className=' flex' >

                </div>

                <div id="header" className=' flex' >
                    <div className="contain">
                        <div className="bnd-d">
                            <img src="/images/logo.png" alt='' className='brand' />
                        </div>

                        <div className="header-content">
                            <h1 className="tpn" style={{ fontWeight: 'bold', color: 'white' }}>WE ARE <br />
                                <span style={{ fontWeight: 'bold', color: 'white' }}>
                                    {text}
                                </span>
                                <Cursor />
                            </h1>
                            <h5>SHAH PHOTOGRAPHERS</h5>
                            <a href="https://www.facebook.com/shahphotographer.palghar/" target="_blank"><FaFacebookSquare style={{ marginRight: 10 }} className='ico' /></a>
                            <a href="https://www.instagram.com/shah.photographer/" target="_blank"><FaInstagramSquare style={{ marginRight: 10 }} className='ico' /></a>
                            <a href="/" target="_blank"><FaTwitterSquare style={{ marginRight: 10 }} className='ico' /></a>
                            <a href="https://youtube.com/@shahphotographer3468" target="_blank"><FaYoutubeSquare className='ico' /></a>
                        </div>

                    </div>
                </div>
            </div>

            <div className="tgsec">
                <section className="about py-7">
                    <div className="container1">
                        <div className="tg-cnt">
                            <div className="line"></div>
                            <div className="tag">
                                <span><b>"</b> Enriching Moments to Memories</span> <span className="snc"> Since 1988.... "</span>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="about1" className="about1 py-7">
                    <div className="container">
                        <div className="about-content">
                            <div className="about-left">
                                <img src="/images/logo_a.jpg" alt="" className='about-img' />
                            </div>
                            <div className="about-right">
                                <div className="title">
                                    <h2>About Us</h2>
                                </div>
                                <p className="lead">We, Shah Photographer combine our photography skills with marketing skills to find the right balance between creativity and commercial sinse. Shah Photographer team is experienced and all round photographer and videographer whose approach to  storytelling focuses on experiencing reality in most direct way.</p>
                                <p className="lead">You can checkout our recent works and experiences below. We considers photography as our passion more than profession.</p>
                                
                            </div>
                        </div>
                    </div>
                </section>

                <div className="sec2">
                    <div id='work' className="work">
                        <div className="container">
                            <div className="work-content">
                                <div className="title">
                                    <h2>What we do</h2>
                                </div>
                                <ul className="work-top grid">
                                    <li className="lead"><i className="fas fa-dot-circle"></i>  Pre-Wedding/ Wedding shoot/ Cinematic</li>
                                    <li className="lead"><i className="fas fa-dot-circle"></i>  Event Broadcasting/ LED Screens/ Live Mixing</li>
                                    <li className="lead"><i className="fas fa-dot-circle"></i>  Photo and Video editing</li>
                                    <li className="lead"><i className="fas fa-dot-circle"></i>  Product/ Commercial and corporate</li>
                                </ul>

                                <div className="work-bottom">
                                    <div>
                                        <span className="icon">
                                            <CgRing className='workbt' />
                                            <h3>Wedding</h3>
                                        </span>
                                    </div>
                                    <div>
                                        <span className="icon">
                                            <CgCamera className='workbt' />
                                            <h3>Live Broadcasting</h3>
                                        </span>
                                    </div>
                                    <div>
                                        <span className="icon">
                                            <RiImageEditFill className='workbt' />
                                            <h3>Editing</h3>
                                        </span>
                                    </div>
                                    <div>
                                        <span className="icon">
                                            <BsCameraReels className='workbt' />
                                            <h3>Commercial</h3>
                                        </span>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <div className="workimg">

                    </div>



                </div>

                <div className="col-d">

                    <div className="color">
                        <div className="t1">We Add</div>
                        <div className="t2">colours</div>
                        <div className="t3">to your life</div>
                    </div>
                </div>


                <div className="sec3">
                    <div id="portfolio">
                        <div className="container">
                            <div className="portfolio-content">
                                <div className="title">
                                    <h2>Our works</h2>
                                </div>
                                <div className="portfolio-grid ">

                                    <div className="img1">
                                        <div className="im1-txt">
                                            <span className="im1-td"> Wedding</span>
                                            <span className="exp">
                                                
                                                <a href="https://photos.app.goo.gl/9oT1si5seK8mrgNk9" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>

                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/wed1.jpg`} className="im1" alt=""/>

                                        </div>


                                    </div>

                                    <div className="img1 grid-col-span">
                                        <div className="im1-txt">
                                            <span className="im1-td"> Candid</span>
                                            <span className="exp">
                                                <a href="https://photos.app.goo.gl/2ukvvvPH9LiwUqem6" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>
                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/port-22.jpg`} alt="" className="im1 im33" />

                                        </div>


                                    </div>


                                    <div className="img1">
                                        <div className="im1-txt">
                                            <span className="im1-td"> Live Events</span>
                                            <span className="exp">
                                                <a href="https://photos.app.goo.gl/CxwqZMqaUTVxgRVe9" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>
                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/nav1.jpg`} alt="" className="im1" />

                                        </div>


                                    </div>

                                    <div className="img1">
                                        <div className="im1-txt">
                                            <span className="im4-td"> Pre Wedding</span>
                                            <span className="exp">
                                                <a href="https://photos.app.goo.gl/KJ8yauZvLt4U3D4t6" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>
                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/pre-wed1.jpg`} alt="" className="im1" />

                                        </div>


                                    </div>

                                    <div className="img1">
                                        <div className="im1-txt">
                                            <span className="im4-td"> Baby Shower</span>
                                            <span className="exp">
                                                <a href="https://photos.app.goo.gl/dZC1bLh1eLwygaLL8" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>
                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/bbswr.jpg`} alt="" className="im1" />

                                        </div>


                                    </div>

                                    <div className="img1 grid-col-span">
                                        <div className="im1-txt">
                                            <span className="im1-td"> Aerial</span>
                                            <span className="exp">
                                                <a href="/" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>
                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/port-66.jpg`} alt="" className="im1 im33" />

                                        </div>


                                    </div>

                                    <div className="img1">
                                        <div className="im1-txt">
                                            <span className="im4-td"> Social work</span>
                                            <span className="exp">
                                                <a href="https://photos.app.goo.gl/Pki35egfEVnVNkxe7" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>
                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/sclwk.jpg`} alt="" className="im1" />

                                        </div>


                                    </div>
                                    <div className="img1">
                                        <div className="im1-txt">
                                            <span className="im4-td"> Corporate Event</span>
                                            <span className="exp">
                                                <a href="https://photos.app.goo.gl/UzcTjE6DFqL9GBDa6" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>
                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/corpo.png`} alt="" className="im1" />

                                        </div>


                                    </div>
                                    <div className="img1">
                                        <div className="im1-txt">
                                            <span className="im4-td"> Product Photography</span>
                                            <span className="exp">
                                                <a href="https://photos.app.goo.gl/druPhzJQdJmhoaz39" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>
                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/Product-Photography.jpeg`} alt="" className="im1" />

                                        </div>


                                    </div>
                                    <div className="img1">
                                        <div className="im1-txt">
                                            <span className="im4-td"> Birthday</span>
                                            <span className="exp">
                                                <a href="https://photos.app.goo.gl/ma9Wjz1ufrp7yk7Q6" className="btn-down-white">
                                                    <i className="fas fa-chevron-down"></i> Explore
                                                </a>
                                            </span>
                                        </div>
                                        <div className="im1-d">
                                            <img src={`/images/birthday-shoot.jpg`} alt="" className="im1" />

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sec7">
                    <div className="title">
                        <h2>Video Gallary</h2>
                    </div>
                    <div className="gallary">

                        <Carousel
                            autoPlaySpeed={3500}
                            ref={carouselRef}
                            onNextEnd={({ index }) => {

                                clearTimeout(resetTimeout)
                                if (index + 1 > 5) {
                                    resetTimeout = setTimeout(() => {
                                        carouselRef.current.goTo(0)
                                    }, 3500) // same time
                                }
                            }}
                            itemsToShow={window.innerWidth < 860 ? 1 : 2}>

                            {
                                videosList?.map((v, i) => (
                                    <div className='vid' key={i}>
                                        <iframe
                                            className='vid-frame'
                                            src={videosList[i]}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        >
                                        </iframe>
                                    </div>
                                ))
                            }

                        </Carousel>
                    </div>

                </div>

                <div className="sec4">
                    <div id="contact">
                        <div className="container">
                            <div className="contact-content">
                                <div className="contact-left">
                                    <div className="title">
                                        <h2>Contact us</h2>
                                    </div>
                                    <p className="lead"><i className="fas fa-phone-alt"></i> +91 9923523468/9226153060</p>
                                    <p className="lead"><i className="fas fa-envelope"></i> shahphotographer@gmail.com</p>
                                    <p className="lead"><i className="fas fa-map-marker"></i> A/P Tembhode, Near Padmanabh Mandir, Tal. Dist. Palghar-401404</p>
                                </div>
                                <div className="contact-right">

                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.2803225520456!2d72.75221377494303!3d19.700686081636757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be71db34081e0bd%3A0x40cdcbe72436762a!2sSHAH%20PHOTOGRAPHER!5e0!3m2!1sen!2sin!4v1685354262883!5m2!1sen!2sin"
                                        title="map"
                                        style={{ border: 0, width: "100%", height: "100%" }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="sec5">
                    <div id="insta">
                        <div className="container">
                            <div className="insta-content">
                                <div className="title">
                                    <h2>Instagram</h2>
                                </div>
                                <div className="insta-grid grid">
                                    <div className='in-d'><img src={`images/insta1.jpg`} alt="" className="in-img" /></div>
                                    <div className='in-d'><img src={`images/insta2.jpg`} alt="" className="in-img" /></div>
                                    <div className='in-d'><img src={`images/insta3.jpg`} alt="" className="in-img" /></div>
                                    <div className='in-d'><img src={`images/insta4.jpg`} alt="" className="in-img" /></div>


                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="inst-img">

                    </div>
                </div>

                <div className="sec6">
                    <div id="footer">
                        <div className="container">
                            <div className="footer-content">
                                <div>
                                    <h3>SHAH PHOTOGRAPHER</h3>
                                    <div><img src="/images/logo_a.jpg" alt='' className="foot-logo" /></div>
                                </div>

                                <div>
                                    <h3>Links</h3>
                                    <ul className="flex">
                                        <li><a href="#about1">About Us</a></li>
                                        <li><a href="#work">Work</a></li>
                                        <li><a href="#portfolio">Portfolio</a></li>
                                        <li><a href="#contact">Contact Us</a></li>
                                        <li><a href="https://www.instagram.com/shah.photographer/">Instagram</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            

        </>



    )
}



export default Home
