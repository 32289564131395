import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout/Layout';
import AdminMenu from "./../../components/Layout/AdminMenu";
import toast from "react-hot-toast";
import axios from "axios";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
const { Option } = Select;

const UpdateVideo = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [photo, setPhoto] = useState("");
    const [id, setId] = useState("");
    const [featured, setFeatured] = useState("no");

    //get single product
    const getSingleVideo = async () => {
        try {
            const { data } = await axios.get(
                `/api/v1/videos/get-video/${params.slug}`
            );
            setName(data.video.name);
            setId(data.video._id);
            setDescription(data.video.description);
            setCategory(data.video.category._id);
            setFeatured(data.video.featured)
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getSingleVideo();
        //eslint-disable-next-line
    }, []);
    //get all category
    const getAllCategory = async () => {
        try {
            const { data } = await axios.get("/api/v1/category/get-category");
            if (data?.success) {
                setCategories(data?.category);
            }
        } catch (error) {
            console.log(error);
            toast.error("Something wwent wrong in getting catgeory");
        }
    };

    useEffect(() => {
        getAllCategory();
    }, []);

    //create product function
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const productData = new FormData();
            productData.append("name", name);
            productData.append("description", description);
            productData.append("category", category);
            productData.append("featured", featured);
            const { data } = axios.put(
                `/api/v1/videos/update-video/${id}`,
                productData
            );
            if (data?.success) {
                toast.error(data?.message);
            } else {
                toast.success("Video Updated Successfully");
                navigate("/dashboard/admin/videos");
            }
        } catch (error) {
            console.log(error);
            toast.error("something went wrong");
        }
    };

    //delete a product
    const handleDelete = async () => {
        try {
            let answer = window.prompt("Are You Sure want to delete this video ? ");
            if (!answer) return;
            const { data } = await axios.delete(
                `/api/v1/videos/delete-video/${id}`
            );
            toast.success("Product DEleted Succfully");
            navigate("/dashboard/admin/videos");
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };
    return (
        <Layout title={"Dashboard - Create Product"}>
            <div className="container-fluid m-3 p-3">
                <div className="row">
                    <div className="col-md-3">
                        <AdminMenu />
                    </div>
                    <div className="col-md-9">
                        <h1>Update Video Details</h1>
                        <div className="m-1 w-75">
                            <Select
                                bordered={false}
                                value={category}
                                placeholder="Select a category"
                                size="large"
                                showSearch
                                className="form-select mb-3"
                                onChange={(value) => {
                                    setCategory(value);
                                }}
                            >
                                {categories?.map((c) => (
                                    <Option key={c._id} value={c._id}>
                                        {c.name}
                                    </Option>
                                ))}
                            </Select>

                            <div className="mb-3">

                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    value={name}
                                    placeholder="write a name"
                                    className="form-control"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <textarea
                                    type="text"
                                    value={description}
                                    placeholder="Embed video src link"
                                    className="form-control"
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label >
                                    Feature on Home page
                                </label>
                            </div>
                            <div className="mb-3">
                                <label for="featured_yes">
                                    <input type="radio" id="featured_yes" value="yes" name="feature" onChange={() => setFeatured("1")} />
                                    Yes
                                </label>
                            </div>
                            <div className="mb-3">
                                <label for="featured_no">
                                    <input type="radio" id="featured_no" value="no" name="feature" onChange={() => setFeatured("0")} />
                                    No
                                </label>
                            </div>
                            <div className="mb-3">
                                <button className="btn btn-primary" onClick={handleUpdate}>
                                    UPDATE DETAILS
                                </button>
                            </div>
                            <div className="mb-3">
                                <button className="btn btn-danger" onClick={handleDelete}>
                                    DELETE VIDEO
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default UpdateVideo;
