import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
    <div className="sec6">
                    <div id="footer">
                        <div className="container">
                            <div className="footer-content">
                                <div>
                                    <h3>SHAH PHOTOGRAPHER</h3>
                                    <div><img src="/images/logo_a.jpg" className="foot-logo" /></div>
                                </div>

                                <div>
                                    <h3>Links</h3>
                                    <ul className="flex">
                                        <li><a href="#about1">About Us</a></li>
                                        <li><a href="#work">Work</a></li>
                                        <li><a href="#portfolio">Portfolio</a></li>
                                        <li><a href="#contact">Contact Us</a></li>
                                        <li><a href="#insta">Instagram</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </>
  );
};

export default Footer;
